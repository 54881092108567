<template>
  <view-card-template :loaded="loaded">

    <template #cardTitle>
      Voter Maintenance
    </template>

    <template #cardBody>
      <v-data-table 
        :headers="headers" 
        :items="filteredUsers" 
        :items-per-page="20" 
        class="expanding-v-data-table"
        dense
      >
        <template #top>
          <v-row class="pa-2 flex-grow-0">
            <v-col
              cols="12"
              sm="2"
            >
              <v-text-field
                v-model="filterName"
                class="ml-2 mt-0"
                label="Filter by Voter Name"
                hide-details
              />
            </v-col>            
            <v-col
              cols="12"
              sm="2"
            >
              <v-combobox
                v-model="filterSchools"
                :items="schoolList"
                clearable
                item-text="name"
                item-value="name"
                class="ml-2 mt-0"
                label="Filter by School"
                multiple
              />
            </v-col>
            <v-col
              cols="12"
              sm="2"
            >
              <v-select
                class="ml-2 mt-0"
                clearable
                v-model="filterSport"
                :items= "sportList"
                item-text="name"
                item-value="globalSportId"
                label="Filter by Sport Name"
                multiple
              />
            </v-col>
            <v-col
              cols="12"
              sm="2"
            >
              <v-combobox
                v-model="filterCategories"
                :items="categoryList"
                clearable
                item-text="name"
                item-value="name"
                class="ml-2 mt-0"
                label="Filter by Category"
                multiple
              sm="4"
            />
            </v-col>
            <v-col
              cols="12"
              sm="2"
            >
              <v-combobox
                v-model="filterStatus"
                :items="statusList"
                class="ml-2 mt-0"
                label="Filter by Status"
                multiple
              />
            </v-col>
            <v-col
              cols="12"
              sm="2"
            >
              <div
                class="d-flex align-center"
                style="height: 100%;"
              >
                <v-btn
                  @click="openModal(null)"
                  color="primary"
                  hide-details
                  block
                >
                  New Voter
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </template>

        <template #item="{ item }">
          <tr>
            <td>{{ item.firstName }}</td>
            <td>{{ item.lastName }}</td>
            <td>{{ item.school }}</td>
            <td>{{ item.email }}</td>
            <td>
              <v-row no-gutters>
                <v-col rows="12">
                  <div
                    class="w-100 d-flex align-center justify-center"
                    style="height: 100%;"
                  >
                    <!-- Edit -->
                    <v-tooltip top>
                      <template v-slot:activator="{on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          @click="openModal(item)"
                          icon
                        >
                          <v-icon   
                            color="blue darken-2"
                            small
                          >
                            mdi-pencil
                          </v-icon>
                        </v-btn>
                      </template>
                      Edit Voter
                    </v-tooltip>
                    <!-- Enable -->
                    <v-tooltip v-if="item.disabled" top>
                      <template v-slot:activator="{on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          @click="enableUser(item)"
                          icon
                        >
                          <v-icon   
                            color="amber accent-2"
                            small
                          >
                            mdi-account-lock-open
                          </v-icon>
                        </v-btn>
                      </template>
                      Enable Voter
                    </v-tooltip>
                    <!-- Disable -->
                    <v-tooltip v-if="!item.disabled" top>
                      <template v-slot:activator="{on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          @click="disableUser(item)"
                          icon
                        >
                          <v-icon   
                            color="red accent-2"
                            small
                          >
                            mdi-account-lock
                          </v-icon>
                        </v-btn>
                      </template>
                      Disable Voter
                    </v-tooltip>
                    <!-- Set Temporary Password -->
                    <v-tooltip top>
                      <template v-slot:activator="{on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          @click="openSetTemporaryPasswordModal(item)"
                          icon
                        >
                          <v-icon   
                            color="teal"
                            small
                          >
                            mdi-lock-reset
                          </v-icon>
                        </v-btn>
                      </template>
                      Set Temporary Password
                    </v-tooltip>
                    <!-- Login As / Impersonate -->
                    <v-tooltip top>
                      <template v-slot:activator="{on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          @click="impersonateUser(item)"
                          icon
                        >
                          <v-icon   
                            color="blue-grey"
                            small
                          >
                            mdi-login
                          </v-icon>
                        </v-btn>
                      </template>
                      Login As
                    </v-tooltip>
                  </div>
                </v-col>
              </v-row>
            </td>
          </tr>
        </template>

        <!-- Expanding Footer -->
        <template #footer="{ props }">
          <v-divider v-if="props.pagination.itemsLength > 0"/>
          <div class="expanding-v-data-table--footer"></div>
        </template>
      </v-data-table>
       
      <app-modal
        v-if="setTemporaryPasswordModalOpen"
        :value="setTemporaryPasswordModalOpen"
        :persistent="true"
        :header-text="`Set Temporary Password for ${setTemporaryPasswordUser.firstName} ${setTemporaryPasswordUser.lastName}`"
        width="auto"
        min-width="800px"
      >
        <template #subheader>
          <v-alert
            type="warning"
            text
          >
            The user will be prompted to reset their password after logging in with the temporary password.
          </v-alert>
        </template>
        <template #content>
          <v-form 
            ref="form"
            @submit.prevent="setTemporaryPassword"
          >
            <v-row>
              <!-- Temporary Password -->
              <v-col>
                <div class="mx-2">
                  <v-text-field
                    v-model="temporaryPassword"
                    label="Temporary Password"
                    hide-details="auto"
                    item-disabled="enable"
                    :rules="[
                      (input) => { return (input != null) || 'Required'},
                      (input) => { return (input.length >= 8) || 'Min 8 characters'},
                      (input) => { return (input.length < 256) || 'Max 256 characters'},
                      (input) => { return (input.toLowerCase() !== input) || 'Must contain an uppercase letter'},
                      (input) => { return (input.toUpperCase() !== input) || 'Must contain a lowercase letter'},
                      (input) => { return (
                        input.match(validSymbolRegex) !== null || 
                        input.indexOf('^') !== -1 || 
                        input.indexOf('[') !== -1 ||
                        input.indexOf(']') !== -1 ||
                        input.indexOf('/') !== -1 ||
                        input.indexOf('\\') !== -1
                      ) ||
                      'Must contain a symbol'},
                    ]"
                  />
                </div>
              </v-col>
            </v-row>
          </v-form>
        </template>
        <template #action>
          <v-spacer></v-spacer>
          <v-btn
            @click="closeSetTemporaryPasswordModal"
            depressed
          >
            Cancel
          </v-btn>
          <v-btn
            @click="setTemporaryPassword()"
            :loading="setTemporaryPasswordLoading"
            class="ml-2"
            color="primary"
            depressed
          >
            Confirm
          </v-btn>
        </template>
      </app-modal>

      <app-modal
        v-if="modalOpen"
        :value="modalOpen"
        :persistent="true"
        :header-text="modalHeaderText"
        width="auto"
        min-width="800px"
      >
        <template #content>
          <v-row
            class="my-3"
            no-gutters
          >
            <!-- First Name -->
            <v-col cols="6">
              <div class="mx-2">
                <v-text-field
                  v-model="modalUser.firstName"
                  label="First Name"
                  hide-details="auto"
                  item-disabled="enable"
                />
              </div>
            </v-col>
            <!-- Last Name -->
            <v-col cols="6">
              <div class="mx-2">
                <v-text-field
                  v-model="modalUser.lastName"
                  label="Last Name"
                  hide-details="auto"
                  item-disabled="disable"
                />
              </div>
            </v-col>
          </v-row>

          <v-row
            class="my-3"
            no-gutters
          >
            <!-- Email -->
            <v-col cols="6">
              <div class="mx-2">
                <v-text-field
                  v-model="modalUser.email"
                  label="Email"
                  hide-details="auto"
                  item-disabled="disable"
                />
              </div>
            </v-col>
            <!-- School -->
            <v-col cols="6">
              <div class="mx-2">
                <v-select
                  v-model="modalUser.school"
                  :items= "schoolList"
                  item-text="name"
                  item-value="name"
                  label="School"
                  hide-details="auto"
                  item-disabled="disable"
                />
              </div>
            </v-col>
          </v-row>

          <v-row
            class="my-3"
            no-gutters
          >
            <!-- Sport -->
            <v-col cols="6">
              <div class="mx-2">
                <v-select
                  v-model="modalUser.globalSportId"
                  :items= "sportList"
                  item-text="name"
                  item-value="globalSportId"
                  label="Sport"
                  hide-details="auto"
                  item-disabled="disable"
                  clearable
                />
              </div>
            </v-col>
            <!-- Category -->
            <v-col cols="6">
              <div class="mx-2">
                <v-select
                  v-model="modalUser.categoryId"
                  :items="categoryList"
                  item-text="name"
                  item-value="id"
                  label="Category"
                  hide-details="auto"
                  item-disabled="enable"
                />
              </div>
            </v-col>
          </v-row>
        </template>
        <template #action>
          <v-spacer></v-spacer>
          <v-btn
            @click="cancelModal"
            depressed
          >
            Cancel
          </v-btn>
          <v-btn
            @click="submitUser()"
            :loading="submitUserLoading"
            class="ml-2"
            color="primary"
            depressed
          >
            Apply
          </v-btn>
        </template>
      </app-modal>
    </template>
  </view-card-template>
</template>

<script>
import ViewCardTemplate from '@/components/templates/ViewCardTemplate.vue'
import AppModal from "@/components/app/AppModal"

import Controllers from "@/data/controllers"
import Identity from '@/app/Identity'
import { SetImpersonationUserId } from "@/data/Requests"

export default {
  name: "VoterMaintenance",

  components: {
      AppModal,
      ViewCardTemplate,
  },

  data: () => ({
    loaded: false,
    
    filterName: "",
    filterSport: "",
    filterStatus: ['Enabled'],
    filterSchools: [],
    filterCategories: [],

    userList: [],
    sportList: [],
    schoolList: [],
    categoryList: [],
    statusList: ['Enabled', 'Disabled'],

    headers: [
      {
        text: 'First Name',
        align: 'start',
        sortable: true,
        value: 'firstName'
      },
      { text: 'Last Name', value: 'lastName' },
      { text: 'School', value: 'school' },
      { text: 'Email', value: 'email' },
      { text: "Actions", sortable: false, align: "center" },
    ],

    modalOpen: false,
    modalHeaderText: "Edit Voter",
    setTemporaryPasswordModalOpen: false,
    setTemporaryPasswordLoading: false,
    setTemporaryPasswordUser: null,
    validSymbolRegex: /[$*.{}()?!@#%&,"><':;|_~`=+-]+/g,
    temporaryPassword: "",
    creatingUser: false,
    conferenceId: null,
    modalUser: {},
    submitUserLoading: false,
  }),

  computed: {
    filteredUsers() {
      return this.userList
      .filter(user => this.filterName == null || this.filterName.length == 0
          || (`${user.firstName} ${user.lastName}`).toLocaleLowerCase().includes(this.filterName.toLocaleLowerCase())
          || (`${user.lastName}, ${user.firstName} `).toLocaleLowerCase().includes(this.filterName.toLocaleLowerCase())
        )
        .filter(user => this.filterSport == null || this.filterSport.length == 0
          || this.filterSport.includes(user.globalSportId)
        )
        .filter(user => this.filterStatus == null || this.filterStatus.length == 0
          || !user.disabled && this.filterStatus.includes("Enabled")
          || user.disabled && this.filterStatus.includes("Disabled")
        )
        .filter(user => this.filterSchools == null || this.filterSchools.length == 0
          || this.filterSchools.map(el => { return el.name.toLocaleLowerCase() }).includes(user.school.toLocaleLowerCase())
        )
        .filter(user => this.filterCategories == null || this.filterCategories.length == 0
          || this.filterCategories.map(el => { return el.id }).includes(user.categoryId)
        )
    },
  },

  methods: {
    openModal(item) {
      if (item == null) {
        // Creating
        this.creatingUser = true
        this.modalHeaderText = "New Voter"
        this.modalUser = {
          id: 0,
          firstName: "",
          lastName: "",
          email: "",
          school: "",
          globalSportId: null,
          teamId: null,
          disabled: false,
          conferenceId: 0,
          categoryId: 0,
          newUser: true,
        }
      }
      else {
        // Editing
        this.creatingUser = false
        this.modalHeaderText = "Edit Voter"
        Object.assign(this.modalUser, item)
      }

      this.modalOpen = true
    },

    cancelModal() {
      this.modalOpen = false
    },

    openSetTemporaryPasswordModal(user) {
      this.setTemporaryPasswordUser = user
      this.setTemporaryPasswordModalOpen = true
    },

    closeSetTemporaryPasswordModal() {
      this.setTemporaryPasswordModalOpen = false
      this.setTemporaryPasswordUser = null
      this.temporaryPassword = ""
    },

    async setTemporaryPassword() {
      if (!this.$refs.form.validate()) return
      this.setTemporaryPasswordLoading = true
      
      const res = await Controllers.UserController.AdminSetUserPassword(this.setTemporaryPasswordUser.id, this.temporaryPassword);

      if(res && !res.hasError) {
        this.$root.showSnackbar('Temporary password set successfully!', "success", 5000)
      }
      else this.$root.showSnackbar('Failed to set temporary password for voter.', "error", 5000)

      this.setTemporaryPasswordLoading = false
      this.closeSetTemporaryPasswordModal();
    },

    async submitUser() {
      if(this.submitUserLoading) return
      this.submitUserLoading = true
      // TODO: Validation
      const school = this.schoolList.find(s => s.name == this.modalUser.school)
      this.modalUser.teamId = Number(school.ncaaTeamId)

      if(this.creatingUser) {
        const res = await Controllers.UserController.AdminCreateUser(this.modalUser)
        
        if(res && !res.hasError) {
          this.userList.push(res.data)
          this.$root.showSnackbar('Voter created successfully!', "success", 5000)
        }
        else this.$root.showSnackbar('Failed to create voter.', "error", 5000)
      }
      else {
        const res = await Controllers.UserController.AdminUpdateUser(this.modalUser)

        if(res && !res.hasError) {
          const oldUser = this.userList.find(user => user.id == this.modalUser.id)
          Object.assign(oldUser, res.data)
          this.$root.showSnackbar('Voter edited successfully!', "success", 5000)
        }
        else this.$root.showSnackbar('Failed to edit voter.', "error", 5000)
      }

      this.submitUserLoading = false
      this.modalOpen = false
    },

    async enableUser(user) {
      const res = await Controllers.UserController.AdminEnableUser(user.id)
      if(res && !res.hasError) {
        user.disabled = !user.disabled
        this.$root.showSnackbar('Voter enabled successfully!', "success", 5000)
      }
      else {
        this.$root.showSnackbar('Failed to enable Voter.', "error", 5000)
      }
    },

    async disableUser(user) {
      const res = await Controllers.UserController.AdminDisableUser(user.id)
      if(res && !res.hasError) {
        user.disabled = !user.disabled
        this.$root.showSnackbar('Voter disabled successfully!', "success", 5000)
      }
      else {
        this.$root.showSnackbar('Failed to disable Voter.', "error", 5000)
      }
    },

    impersonateUser(user) {
      Identity.SetUser(user)
      SetImpersonationUserId(user.id)
      this.$router.push({ name: "Voting" })
    },


    async getUserList() {
      const res = await Controllers.UserController.AdminListUsers()
      if(res && !res.hasError) this.userList = res.data
      else this.$root.showSnackbar('Failed to load voters', "error", 5000)
    },

    async getSportList() {
      const sports = await this.$store.getters.conferenceSports
      this.sportList = sports
    },

    async getSchoolList() {
      const res = await Controllers.ConferenceController.ListSchools()
      if(res && !res.hasError) this.schoolList = res.data
    },

    async getCategoryList() {
      const res = await Controllers.CategoryController.ListCategories()
      if(res && !res.hasError) this.categoryList = res.data
    },

  },

  async created() {
    this.$root.updateTitle("Voter Maintenance")
    
    await Promise.all([
      this.getUserList(),
      this.getSportList(),
      this.getSchoolList(),
      this.getCategoryList(),
    ])

    this.loaded = true
  },
}
</script>